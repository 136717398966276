import React, {useContext} from 'react';
import {Card, Notification, toast} from "../../../../../../../../components/ui";
import PageHeader from "../../../../../../../../components/ui/Page/PageHeader";
import {HiPaperAirplane, HiUser} from "react-icons/hi";
import {Form, Formik} from "formik";
import FormGroup from "../../../../../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../../../../../components/RefactoredForms/FormInput";
import CancelButton from "../../../../../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../../../../../components/ui/Buttons/_components/SaveButton";
import EditButton from "../../../../../../../../components/ui/Buttons/_components/EditButton";
import {CompanyEmployeeContext} from "../CompanyEmployeeShow";
import employeesService from "../../../../../../../../services/employeesService";
import Button from "../../../../../../../../components/ui/Buttons";
import {useToggle} from "ahooks";

const EmployeeCard = ({employee}) => {

    const [isEditing, setIsEditing] = React.useState(false);
    const [isInviting, {toggle: toggleInviting}] = useToggle(false);

    const handleInviteUser = () => {
        toggleInviting();
        employeesService
            .invite(employee.uuid)
            .then(() => {
                toast.push(<Notification
                    title='Invito inviato con successo'
                    type='success'/>
                );
            })
            .catch(err => {
                toast.push(<Notification title={err.response.data.message}
                                         type='danger'/>)
            })
            .finally(() => {
                toggleInviting();
            });
    }

    if (isEditing) {
        return (<EmployeeEditForm employee={employee} onCancel={() => setIsEditing(false)}/>)
    }

    return (
        <Card bodyClass="flex items-center h-full w-full">
            <PageHeader
                title={employee?.first_name + ' ' + employee?.last_name}
                description={employee?.email || 'Nessuna email'}
                icon={<HiUser className='text-neutral-300' size={30}/>}
                toolbar={<div className="space-x-2">
                    <EditButton size="xs"
                                onlyIcon
                                handleClick={() => setIsEditing(true)}/>

                    {employee.email && !employee.invite_accepted && <Button
                        type='button'
                        loading={isInviting}
                        size="xs"
                        variant='solid'
                        color='orange-500'
                        icon={<HiPaperAirplane/>}
                        onClick={handleInviteUser}
                    />}
                </div>}
            />
        </Card>
    );
};

export default EmployeeCard;


const EmployeeEditForm = ({employee, onCancel}) => {
    const {setEmployment} = useContext(CompanyEmployeeContext);

    return (
        <Card>
            <Formik
                initialValues={employee}
                onSubmit={async (values, {setSubmitting}) => {
                    setSubmitting(true);
                    return employeesService
                        .update(employee.uuid, values)
                        .then(res => {
                            setEmployment(old => ({...old, employee: res}));
                            toast.push(<Notification
                                title='Dipendente aggiornato successo'
                                type='success'/>
                            );
                            onCancel();
                        })
                        .catch(err => {
                            toast.push(<Notification title={err.response.data.message}
                                                     type='danger'/>)
                        }).finally(() => {
                            setSubmitting(false);
                        });
                }}>
                {(f) =>
                    <Form>
                        <div className='space-y-4'>
                            <div className='grid grid-cols-4 lg:grid-cols-2 gap-2'>
                                <FormGroup label='Codice Fiscale' name='fiscal_code' required>
                                    <FormInput name='fiscal_code'/>
                                </FormGroup>
                                <FormGroup label="Indirizzo Email" name="email">
                                    <FormInput name="email" type="email"/>
                                </FormGroup>
                                <FormGroup label="Nome" name="first_name"
                                           required>
                                    <FormInput name="first_name"/>
                                </FormGroup>
                                <FormGroup label="Cognome" name="last_name"
                                           required>
                                    <FormInput name="last_name"/>
                                </FormGroup>
                            </div>
                            <div className='gap-4 flex justify-end'>
                                <CancelButton label="Annulla"
                                              handleClick={() => onCancel()}/>
                                <SaveButton loading={f.isSubmitting}
                                            disabled={!f.isValid}/>
                            </div>
                        </div>
                    </Form>
                }
            </Formik>
        </Card>
    )

}