import MyCompany from "../../../views/protected/myCompany/MyCompany";
import Dashboard from "../../../views/protected/myCompany/dashboard/Dashboard";
import attachmentsRoutes from "./routes/attachmentsRoutes";
import companyRoutes from "./routes/companyRoutes";
import documentRoutes from "./routes/documentRoutes";
import manualsRoutes from "./routes/manualsRoutes";
import rolesRoutes from "./routes/rolesRoutes";
import supervisedRoutes from "./routes/supervised/supervisedRoutes";
import supervisionsRoutes from "./routes/supervisionsRoutes";
import usersRoutes from "./routes/usersRoutes";
import employeesRoutes from "./routes/employeesRoutes";

export const myCompanyRoutes = {
    element: <MyCompany />,
    children: [
        { path: "dashboard", index: true, element: <Dashboard /> },
        { path: "attachments", ...attachmentsRoutes },
        { path: "company", ...companyRoutes },
        { path: "users", ...usersRoutes },
        { path: "roles", ...rolesRoutes },
        { path: "documents", ...documentRoutes },
        { path: "employees", ...employeesRoutes },
        { path: "supervisions", ...supervisionsRoutes },
        { path: "supervised", ...supervisedRoutes },
        { path: "manuals", ...manualsRoutes },
    ],
};
