import React, {createContext, useContext} from "react";
import {Link, Outlet, useParams} from "react-router-dom";
import {CompanyContext} from "../../Company";
import EmployeeCard from "./_components/EmployeeCard";
import employmentsService from "../../../../../../../services/employmentsService";
import {useRequest} from "ahooks";
import {cn} from "../../../../../../../utils/classHelpers";
import {Card} from "../../../../../../../components/ui";

export const CompanyEmployeeContext = createContext({
    loading: true, employment: {},
});

const CompanyEmployeeShow = () => {
    const {company} = useContext(CompanyContext);
    const {employment_id} = useParams();

    const {
        data: employment, loading, mutate
    } = useRequest(employmentsService.fetch, {defaultParams: [employment_id]});

    if (!employment) return null;

    const contextValue = {
        employment: employment, loading, setEmployment: mutate
    };

    const location = window.location.pathname;

    return (
        <CompanyEmployeeContext.Provider value={contextValue}>
            <div className="space-y-2">
                <div className="grid grid-cols-2 gap-4">
                    <EmployeeCard employee={employment.employee}/>
                    <Card>
                        <div className="menu space-y-2">
                            <div
                                className={cn("menu-item hover:bg-sky-50", location.includes('info') ? 'bg-sky-50' : '')}>
                                <Link to={`info`}
                                      className="block px-4 py-2 text-sm text-sky-800">Informazioni</Link>
                            </div>
                            <div
                                className={cn("menu-item hover:bg-sky-50", location.includes('documents') ? 'bg-sky-50' : '')}>
                                <Link to={`documents`}
                                      className="block px-4 py-2 text-sm text-sky-800">Documenti</Link>
                            </div>
                        </div>
                    </Card>
                </div>
                <Outlet/>
            </div>
        </CompanyEmployeeContext.Provider>

    );
};

export default CompanyEmployeeShow;
