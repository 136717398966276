import {Navigate} from "react-router-dom";

import Company from "../../../../../views/protected/admin/companies/company/Company";
import CompanyShow from "../../../../../views/protected/admin/companies/company/CompanyShow";
import CompanyAttachmentCreate
    from "../../../../../views/protected/admin/companies/company/tabs/attachments/CompanyAttachmentCreate";
import CompanyAttachmentShow
    from "../../../../../views/protected/admin/companies/company/tabs/attachments/CompanyAttachmentShow";
import CompanyAttachmentsIndex
    from "../../../../../views/protected/admin/companies/company/tabs/attachments/CompanyAttachmentsIndex";
import CompanyAttachmentsTab
    from "../../../../../views/protected/admin/companies/company/tabs/attachments/CompanyAttachmentsTab";
import CompanyDetailsTab
    from "../../../../../views/protected/admin/companies/company/tabs/details/CompanyDetailsTab";
import CompanyDocumentsTab
    from "../../../../../views/protected/admin/companies/company/tabs/documents/CompanyDocumentsTab";
import CompanyInfoTab
    from "../../../../../views/protected/admin/companies/company/tabs/info/CompanyInfoTab";
import CompanyOfficesTab
    from "../../../../../views/protected/admin/companies/company/tabs/offices/CompanyOfficesTab";
import CompanySupervisedTab
    from "../../../../../views/protected/admin/companies/company/tabs/supervised/CompanySupervisedTab";
import CompanySupervisionTab
    from "../../../../../views/protected/admin/companies/company/tabs/supervision/SupervisorTab";
import CompanyUsersTab
    from "../../../../../views/protected/admin/companies/company/tabs/users/CompanyUsersTab";
import CompanySettingsTab
    from "../../../../../views/protected/admin/companies/company/tabs/settings/CompanySettingsTab";
import CompanyDashboardTab
    from "../../../../../views/protected/admin/companies/company/tabs/dashboard/CompanyDashboardTab";
import CompanyEmployeesTab
    from "../../../../../views/protected/admin/companies/company/tabs/employees/CompanyEmployeesTab";
import CompanyEmployeesIndex
    from "../../../../../views/protected/admin/companies/company/tabs/employees/CompanyEmployeesIndex";
import CompanyEmployeesCreate
    from "../../../../../views/protected/admin/companies/company/tabs/employees/CompanyEmployeesCreate";
import CompanyEmployeeShow
    from "../../../../../views/protected/admin/companies/company/tabs/employees/CompanyEmployeeShow";
import CompanyEmployeeInfo
    from "../../../../../views/protected/admin/companies/company/tabs/employees/showTabs/CompanyEmployeeInfo";
import CompanyEmployeeDocuments
    from "../../../../../views/protected/admin/companies/company/tabs/employees/showTabs/CompanyEmployeeDocuments";

export const companyRoutes = {
    element: <Company/>,
    children: [
        {
            path: "*",
            element: <CompanyShow/>,
            children: [
                {
                    path: "dashboard",
                    element: <CompanyDashboardTab/>,
                },
                {
                    path: "info",
                    element: <CompanyInfoTab/>,
                },
                {
                    path: "details",
                    element: <CompanyDetailsTab/>,
                },
                {
                    path: "settings",
                    element: <CompanySettingsTab/>,
                },
                {
                    path: "offices",
                    element: <CompanyOfficesTab/>,
                },
                {
                    path: "supervisions",
                    element: <CompanySupervisionTab/>,
                },
                {
                    path: "supervised",
                    element: <CompanySupervisedTab/>,
                },
                {
                    path: "users",
                    element: <CompanyUsersTab/>,
                },
                {
                    path: "attachments/*",
                    element: <CompanyAttachmentsTab/>,
                    children: [
                        {
                            path: "",
                            element: <CompanyAttachmentsIndex/>,
                        },
                        {
                            path: "create",
                            element: <CompanyAttachmentCreate/>,
                        },
                        {
                            path: ":id",
                            element: <CompanyAttachmentShow/>,
                        },
                    ],
                },
                {
                    path: "documents",
                    element: <CompanyDocumentsTab/>,
                    children: [
                        {
                            path: ":cat",
                            element: <CompanyDocumentsTab/>,
                        },
                        {
                            path: ":cat/:num_a",
                            element: <CompanyDocumentsTab/>,
                        },
                        {
                            path: ":cat/:num_a/:num_b",
                            element: <CompanyDocumentsTab/>,
                        },
                    ],
                },
                {
                    path: "employees/*",
                    element: <CompanyEmployeesTab/>,
                    children: [
                        {
                            path: "",
                            element: <CompanyEmployeesIndex/>,
                        },
                        {
                            path: "create",
                            element: <CompanyEmployeesCreate/>,
                        },
                        {
                            path: ":employment_id",
                            element: <CompanyEmployeeShow/>,
                            children: [
                                {
                                    path: "",
                                    element: <Navigate to='info'/>,
                                },
                                {
                                    path: "info",
                                    element: <CompanyEmployeeInfo/>,
                                },
                                {
                                    path: "documents",
                                    element: <CompanyEmployeeDocuments/>,
                                    children: [
                                        {
                                            path: ":cat",
                                            element: <CompanyEmployeeDocuments/>,
                                        },
                                        {
                                            path: ":cat/:num_a",
                                            element: <CompanyEmployeeDocuments/>,
                                        },
                                        {
                                            path: ":cat/:num_a/:num_b",
                                            element: <CompanyEmployeeDocuments/>,
                                        },
                                    ],
                                },
                            ]
                        },
                    ],
                },
                {
                    path: "",
                    element: <Navigate to='dashboard'/>,
                },
            ],
        },
    ],
};
