import {Form, Formik} from "formik";
import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {useSanctum} from "react-sanctum";
import * as Yup from "yup";

import FormCheckbox from "../../../../../components/RefactoredForms/FormCheckbox";
import FormDescription from "../../../../../components/RefactoredForms/FormDescription";
import FormError from "../../../../../components/RefactoredForms/FormError";
import FormGroup from "../../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../../components/RefactoredForms/FormInput";
import FormSelect from "../../../../../components/RefactoredForms/FormSelect";
import {Card, Notification} from "../../../../../components/ui";
import CancelButton from "../../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../../components/ui/Page/PageHeader";
import toast from "../../../../../components/ui/toast";
import usersService from "../../../../../services/usersService";
import {MyCompanyUserContext} from "./User";
import MyCompanyUserCommonData from "./components/MyCompanyUserCommonData";
import {useRequest} from "ahooks";
import listService from "../../../../../services/listService";

const UserEdit = () => {
    const {
        user,
        setUser,
        roles,
        canUserSeeAllDocumentTypes,
        canUserSeeAllEmployeeDocumentTypes
    } = useContext(MyCompanyUserContext);

    const {data: documentTypes} = useRequest(listService.publicDocumentTypes);
    const {data: employeeDocumentTypes} = useRequest(listService.publicEmployeeDocumentTypes);


    const navigate = useNavigate();
    const {user: authUser} = useSanctum();

    const initialValues = {
        name: user?.name,
        email: user?.email,
        role: user?.role
            ? {
                value: user.role.id.toString(),
                label: user.role.name,
            }
            : "",
        can_view_all_document_types: canUserSeeAllDocumentTypes ?? false,
        document_types_permitted: user?.company_user.document_types_permitted ?? [],

        can_view_all_employee_document_types: canUserSeeAllEmployeeDocumentTypes ?? false,
        employee_document_types_permitted: user?.company_user.employee_document_types_permitted ?? [],
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(100).required(),
        email: Yup.string().email().required(),
        role: Yup.object().nullable().required(),
        can_view_all_document_types: Yup.boolean().required(),
        document_types_permitted: Yup.array().when("can_view_all_document_types", {
            is: false,
            then: Yup.array().min(1, "Deve essere selezionato almeno un documento").required(),
            otherwise: Yup.array().required(),
        }),

        can_view_all_employee_document_types: Yup.boolean().required(),
        employee_document_types_permitted: Yup.array().when("can_view_all_employee_document_types", {
            is: false,
            then: Yup.array().min(1, "Deve essere selezionato almeno un documento").required(),
            otherwise: Yup.array().required(),
        }),
    });

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    usersService
                        .update(user.uuid, {
                            ...values,
                            document_types_permitted: values.can_view_all_document_types ? [] : values.document_types_permitted,
                            employee_document_types_permitted: values.can_view_all_employee_document_types ? [] : values.employee_document_types_permitted,
                            role: values?.role?.value,
                        })
                        .then(res => {
                            toast.push(<Notification title='Utente aggiornato con successo'
                                                     type='success'/>);
                            setUser(res);
                            navigate("../");
                        })
                        .catch(err => toast.push(<Notification title={err.response.data.message}
                                                               type='danger'/>))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({isValid, isSubmitting, resetForm, values, setValues, errors}) => (
                    <Form className='flex flex-col gap-4'>
                        <Card
                            header={
                                <PageHeader
                                    title='Gestione utente'
                                    toolbar={
                                        <>
                                            <CancelButton disabled={isSubmitting}
                                                          handleClick={() => navigate("../")}
                                                          label={"Indietro"}/>
                                            <SaveButton loading={isSubmitting}
                                                        disabled={!isValid || isSubmitting}/>
                                        </>
                                    }
                                />
                            }
                        >
                            <div className='flex flex-col gap-4'>
                                <MyCompanyUserCommonData/>

                                <div className='grid xl:grid-cols-3 gap-4'>
                                    <FormGroup name={"name"} required label='Nome e cognome'>
                                        <FormInput name={"name"}/>
                                    </FormGroup>
                                    <FormGroup name={"email"} required label='Email'>
                                        <FormInput name={"email"} type='email'/>
                                    </FormGroup>
                                    <FormGroup name={"role"} required label='Ruolo'>
                                        <FormSelect
                                            name={"role"}
                                            options={roles}
                                            placeholder={"Seleziona un ruolo"}
                                            onChange={role =>
                                                setValues({
                                                    ...values,
                                                    role: role,
                                                    can_view_all_document_types: role?.value === "1" ? true : values.can_view_all_document_types,
                                                    document_types_permitted: [],
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </div>

                                <FormDescription
                                    title={"Tipologie documento aziendali"}
                                    desc={"Seleziona le tipologie documento dell'azienda al quale l'utente ha accesso."}
                                />
                                <FormGroup name={"can_view_all_document_types"} label={""}>
                                    <FormCheckbox
                                        name={"can_view_all_document_types"}
                                        disabled={values.role?.value === 1 || values.role?.value === "1"}
                                        label={"Permesso di vedere tutti le tipologie di documento"}
                                    />
                                </FormGroup>
                                {errors["document_types_permitted"] &&
                                    <FormError>{errors["document_types_permitted"]}</FormError>}

                                {!values.can_view_all_document_types && values.role?.value !== "1" && (
                                    <div className='grid xl:grid-cols-2 gap-4'>
                                        {documentTypes?.map(dt => (
                                            <FormCheckbox
                                                key={dt.value}
                                                name={"document_types_permitted"}
                                                label={dt.label}
                                                disabled={values.role?.value === 1}
                                                description={dt.description}
                                                value={dt.value.toString()}
                                            />
                                        ))}
                                    </div>
                                )}


                                <FormDescription
                                    title={"Tipologie documento dipendenti"}
                                    desc={"Seleziona le tipologie documento dei dipendenti al quale l'utente ha accesso."}
                                />
                                <FormGroup name={"can_view_all_employee_document_types"} label={""}>
                                    <FormCheckbox
                                        name={"can_view_all_employee_document_types"}
                                        disabled={values.role?.value === 1 || values.role?.value === "1"}
                                        label={"Permesso di vedere tutti le tipologie di documento"}
                                    />
                                </FormGroup>
                                {errors["employee_document_types_permitted"] &&
                                    <FormError>{errors["employee_document_types_permitted"]}</FormError>}

                                {!values.can_view_all_employee_document_types && values.role?.value !== "1" && (
                                    <div className='grid xl:grid-cols-2 gap-4'>
                                        {employeeDocumentTypes?.map(dt => (
                                            <FormCheckbox
                                                key={dt.value}
                                                name={"employee_document_types_permitted"}
                                                label={dt.label}
                                                disabled={values.role?.value === 1}
                                                description={dt.description}
                                                value={dt.value.toString()}
                                            />
                                        ))}
                                    </div>
                                )}

                                <div className='gap-4 flex justify-end'></div>
                            </div>
                        </Card>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UserEdit;
