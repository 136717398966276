import React, {useContext} from "react";
import {SupervisedContext} from "../SupervisedShow";
import {Outlet} from "react-router-dom";

const SupervisedEmployees = () => {
    const {company} = useContext(SupervisedContext);

    if (!company) return null;

    return <Outlet/>;
};

export default SupervisedEmployees;
