import React from "react";
import {Navigate} from "react-router-dom";

import Supervised from "../../../../../views/protected/myCompany/supervised/Supervised";
import SupervisedIndex from "../../../../../views/protected/myCompany/supervised/SupervisedIndex";
import SupervisedShow from "../../../../../views/protected/myCompany/supervised/SupervisedShow";
import SupervisedDocuments
    from "../../../../../views/protected/myCompany/supervised/tabs/SupervisedDocuments";
import SupervisedEmployees
    from "../../../../../views/protected/myCompany/supervised/tabs/SupervisedEmployees";
import SupervisedEmployeesIndex
    from "../../../../../views/protected/myCompany/supervised/tabs/employees/SupervisedEmployeesIndex";
import SupervisedEmployeesShow
    from "../../../../../views/protected/myCompany/supervised/tabs/employees/SupervisedEmployeesShow";
import SupervisedDashboard
    from "../../../../../views/protected/myCompany/supervised/tabs/SupervisedDashboard";
import SupervisedEmployeeInfo
    from "../../../../../views/protected/myCompany/supervised/tabs/employees/showTabs/SupervisedEmployeeInfo";
import SupervisedEmployeeDocuments
    from "../../../../../views/protected/myCompany/supervised/tabs/employees/showTabs/SupervisedEmployeeDocuments";

const supervisedRoutes = {
    element: <Supervised/>,
    children: [
        {
            path: "",
            element: <SupervisedIndex/>,
        },
        {
            path: ":id/*",
            element: <SupervisedShow/>,
            children: [
                {
                    path: "dashboard",
                    element: <SupervisedDashboard/>,
                },
                {
                    path: "documents",
                    element: <SupervisedDocuments/>,
                    children: [
                        {
                            path: ":cat",
                            element: <SupervisedDocuments/>,
                        },
                        {
                            path: ":cat/:num_a",
                            element: <SupervisedDocuments/>,
                        },
                        {
                            path: ":cat/:num_a/:num_b",
                            element: <SupervisedDocuments/>,
                        },
                    ],
                },
                {
                    path: "employees",
                    element: <SupervisedEmployees/>,
                    children: [
                        {
                            path: "",
                            element: <SupervisedEmployeesIndex/>,
                        },
                        {
                            path: ":employment_id",
                            element: <SupervisedEmployeesShow/>,
                            children: [
                                {
                                    path: "",
                                    element: <Navigate to='info'/>,
                                },
                                {
                                    path: "info",
                                    element: <SupervisedEmployeeInfo/>,
                                },
                                {
                                    path: "documents",
                                    element: <SupervisedEmployeeDocuments/>,
                                    children: [
                                        {
                                            path: ":cat",
                                            element: <SupervisedEmployeeDocuments/>,
                                        },
                                        {
                                            path: ":cat/:num_a",
                                            element: <SupervisedEmployeeDocuments/>,
                                        },
                                        {
                                            path: ":cat/:num_a/:num_b",
                                            element: <SupervisedEmployeeDocuments/>,
                                        },
                                    ],
                                },
                            ]
                        },
                    ],
                },
                {
                    path: "",
                    element: <Navigate to='dashboard'/>,
                },
            ],
        },
    ],
};

export default supervisedRoutes;
