import { first, isArray } from "lodash";
import React from "react";
import { Link } from "react-router-dom";

import PermissionsCheck from "../../../../../components/shared/PermissionsCheck";
import DownloadButton from "../../../../../components/ui/Buttons/_components/DownloadButton";
import PreviewButton from "../../../../../components/ui/Buttons/_components/PreviewButton";
import FormBoolDisplay from "../../../../../components/ui/Form/FormDisplay/FormBoolDisplay";
import DateFormatDisplay from "../../../../../components/ui/Formatter/DateFormatDisplay";
import UserDisplay from "../../../../../components/ui/Users/UserDisplay";
import StateDisplay from "../../../../../components/ui/State/StateDisplay";

export const employmentsCols = [
    {
        header: "Impiegato",
        accessorKey: "employee",
        cell: ({getValue, row: {original}}) =>
            <Link to={original.uuid} className='font-bold'>
                {getValue().last_name} {getValue().first_name}
            </Link>
    },
    {
        header: "Stato",
        accessorKey: "status",
        cell: ({getValue}) => <StateDisplay state={getValue()}/>,
    },
    {
        header: "Data di assunzione",
        accessorKey: "hire_date",
        cell: ({getValue}) => <DateFormatDisplay value={getValue()}/>
    },
    {
        header: "Data di cessazione",
        accessorKey: "termination_date",
        cell: ({getValue}) => <DateFormatDisplay value={getValue()}/>
    },
];
