import React from 'react';
import FormDescription from "../../../../../../../../components/RefactoredForms/FormDescription";
import {Card, Notification, toast} from "../../../../../../../../components/ui";
import {Form, Formik} from "formik";
import FormGroup from "../../../../../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../../../../../components/RefactoredForms/FormInput";
import SaveButton from "../../../../../../../../components/ui/Buttons/_components/SaveButton";
import employeesService from "../../../../../../../../services/employeesService";
import CancelButton from "../../../../../../../../components/ui/Buttons/_components/CancelButton";

const EmployeeCreate = ({fiscal_code, onEmployeeFound, onCancel}) => {

    const handleCreateEmployee = (data) => {
        return employeesService
            .store(data)
            .then(res => {
                onEmployeeFound(res);
            })
            .catch(err => {
                toast.push(<Notification title={err.response.data.message} type='danger'/>)
            });
    }


    return (
        <Card>
            <Formik
                initialValues={{fiscal_code}}
                onSubmit={async (values, {setSubmitting}) => {
                    setSubmitting(true);
                    await handleCreateEmployee(values);
                    setSubmitting(false);
                }}>
                {(f) =>
                    <Form>
                        <div className='flex flex-col gap-4'>
                            <FormDescription title='Crea nuovo dipendente'/>
                            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                                <FormGroup label='Codice Fiscale' name='fiscal_code' required>
                                    <FormInput name='fiscal_code'/>
                                </FormGroup>
                                <FormGroup label="Nome" name="first_name"
                                           required>
                                    <FormInput name="first_name"/>
                                </FormGroup>
                                <FormGroup label="Cognome" name="last_name"
                                           required>
                                    <FormInput name="last_name"/>
                                </FormGroup>
                                <FormGroup label="Indirizzo Email" name="email">
                                    <FormInput name="email" type="email"/>
                                </FormGroup>
                            </div>
                            <div className='gap-4 flex justify-end'>
                                <CancelButton label="Annulla"
                                              handleClick={() => onCancel()}/>
                                <SaveButton label="Crea" loading={f.isSubmitting}
                                            disabled={!f.isValid}/>
                            </div>
                        </div>
                    </Form>
                }
            </Formik>
        </Card>
    );
};

export default EmployeeCreate;
