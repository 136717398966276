import {useRequest} from "ahooks";
import React, {createContext} from "react";
import {matchRoutes, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import {Tabs} from "../../../../components/ui";
import BaseCompanyInfo from "../../../../components/ui/Company/BaseCompanyInfo";
import TabList from "../../../../components/ui/Tabs/TabList";
import TabNav from "../../../../components/ui/Tabs/TabNav";
import companyService from "../../../../services/companyService";
import {first} from "lodash";
import {HiFolderOpen, HiMiniUserGroup} from "react-icons/hi2";
import {MdDashboard} from "react-icons/md";
import Card from "../../../../components/ui/Card";

export const SupervisedContext = createContext({
    company: undefined,
});
const SupervisedShow = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const currentLocation = useLocation();

    const {
        data: company,
        loading,
        error,
    } = useRequest(companyService.fetch, {
        defaultParams: [id],
        onError: error => {
            navigate("/my-company/supervised", {
                replace: true,
                state: {error: "Azienda non trovata"}
            });
        },
    });

    const contextValue = {
        company,
        loading,
    };

    // const [currentTab, setCurrentTab] = useState("documents");

    const tabs = [
        {
            value: "dashboard",
            path: "/my-company/supervised/:id/dashboard/*",
            label: "Dashboard",
            description: "",
            icon: <MdDashboard/>,
            show: true,
        },
        {
            value: "documents",
            path: "/my-company/supervised/:id/documents/*",
            label: "Documenti",
            description: "",
            icon: <HiFolderOpen/>,
            show: true,
        },
        {
            value: "employees",
            path: "/my-company/supervised/:id/employees/*",
            label: "Dipendenti",
            description: "",
            icon: <HiMiniUserGroup/>,
            show: true,
        },
    ];

    const getCurrentMatch = () => first(matchRoutes(tabs, currentLocation)) || undefined;


    if (loading) {
        return <div>Caricamento azienda</div>;
    }

    return (
        <PageContainer>
            <SupervisedContext.Provider value={contextValue}>

                <div className='flex flex-col gap-4'>

                    <Tabs value={getCurrentMatch()?.route?.value || ""}
                          variant="pill"
                          onChange={value => navigate(value)}>
                        <div className="flex flex-row justify-start gap-4">
                            <div className="w-64 flex-shrink-0 space-y-2">
                                <BaseCompanyInfo company={company} loading={loading}/>
                                <Card bodyClass="p-2">
                                    <TabList
                                        className="flex flex-col items-start gap-2 p-0 m-0 overflow-hidden">
                                        {tabs.map(
                                            (tab, index) =>
                                                tab.show && (
                                                    <TabNav key={index} value={tab.value}
                                                            icon={tab?.icon}
                                                            onClick={() => navigate(tab.value)}
                                                            className="w-full justify-start">
                                                        {tab.label}
                                                    </TabNav>
                                                )
                                        )}
                                    </TabList>
                                </Card>
                            </div>
                            <div className='flex-grow'>
                                <Outlet/>
                            </div>
                        </div>
                    </Tabs>
                </div>
            </SupervisedContext.Provider>
        </PageContainer>
    );
};

export default SupervisedShow;
