import React, {createContext, useContext} from "react";
import {Link, Navigate, Outlet, useParams} from "react-router-dom";
import StateDisplay from "../../../../../../components/ui/State/StateDisplay";
import DateFormatDisplay from "../../../../../../components/ui/Formatter/DateFormatDisplay";
import {useRequest} from "ahooks";
import employmentsService from "../../../../../../services/employmentsService";
import {Card} from "../../../../../../components/ui";
import {cn} from "../../../../../../utils/classHelpers";
import EmployeeCard from "./_components/EmployeeCard";
import usePermissions from "../../../../../../utils/hooks/usePermissions";
import {useSanctum} from "react-sanctum";
import PageContainer
    from "../../../../../../components/layout/ProtectedLayout/_components/PageContainer";
import SupervisedEmployeeDeniedCardAlert from "./_components/SupervisedEmployeeDeniedCardAlert";
import {SupervisedContext} from "../../SupervisedShow";


const tableCols = [
    {
        header: "Impiegato",
        accessorKey: "employee",
        cell: ({getValue, row: {original}}) =>
            <Link to={original.uuid} className='font-bold'>
                {getValue().last_name} {getValue().first_name}
            </Link>
    },
    {
        header: "Stato",
        accessorKey: "status",
        cell: ({getValue}) => <StateDisplay state={getValue()}/>,
    },
    {
        header: "Data di assunzione",
        accessorKey: "hire_date",
        cell: ({getValue}) => <DateFormatDisplay value={getValue()}/>
    },
    {
        header: "Data di cessazione",
        accessorKey: "termination_date",
        cell: ({getValue}) => <DateFormatDisplay value={getValue()}/>
    },

];


export const SupervisedEmployeeContext = createContext({
    loading: true, employment: {},
});

const SupervisedEmployeesShow = () => {
    const {employment_id} = useParams();
    const {company} = useContext(SupervisedContext);
    const {user} = useSanctum();

    const {
        data: employment, loading, mutate
    } = useRequest(employmentsService.fetch, {defaultParams: [employment_id]});


    const canViewEmployeeDetails = company.company_settings?.is_employee_detail_active;
    const hasPermission = usePermissions(user.role.permissions, ["employments.view"]);

    if (!canViewEmployeeDetails)
        return (
            <PageContainer>
                <Card>
                    <SupervisedEmployeeDeniedCardAlert
                        message="Il servizio dettaglio dipendenti non è attivo per questa azienda."
                    />
                </Card>
            </PageContainer>
        )

    if (!hasPermission) {
        return <Navigate to='/'/>;
    }

    if (!employment) return null;

    const contextValue = {
        employment: employment, loading, setEmployment: mutate
    };

    const location = window.location.pathname;

    return (
        <SupervisedEmployeeContext.Provider value={contextValue}>
            <div className="space-y-2">
                <div className="grid grid-cols-2 gap-4">
                    <EmployeeCard/>
                    <Card>
                        <div className="menu space-y-2">
                            <div
                                className={cn("menu-item hover:bg-sky-50", location.includes('info') ? 'bg-sky-50' : '')}>
                                <Link to={`info`}
                                      className="block px-4 py-2 text-sm text-sky-800">Informazioni</Link>
                            </div>
                            <div
                                className={cn("menu-item hover:bg-sky-50", location.includes('documents') ? 'bg-sky-50' : '')}>
                                <Link to={`documents`}
                                      className="block px-4 py-2 text-sm text-sky-800">Documenti</Link>
                            </div>
                        </div>
                    </Card>
                </div>
                <Outlet/>
            </div>
        </SupervisedEmployeeContext.Provider>
    );

};

export default SupervisedEmployeesShow;
