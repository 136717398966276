import React, {useState} from "react";
import {HiFolderDownload} from "react-icons/hi";
import {ForceDownload} from "../../../../utils/files";
import {Button, Notification, toast} from "../../index";
import employeeDocumentService from "../../../../services/employeeDocumentService";

const DownloadZipButton = ({
                               employmentId,
                               documentCategoryId,
                               year = undefined,
                               month = undefined,
                               employeeDocumentTypeId = undefined,
                               disabled = false,
                               buttonText,
                               size = "sm",
                           }) => {
    const [loading, setLoading] = useState(false);

    const handleDownload = () => {
        setLoading(true);
        employeeDocumentService
            .downloadZip({
                employment_id: employmentId,
                per_page: -1,
                filter: {
                    document_category_id: documentCategoryId,
                    year: year,
                    month: month,
                    employee_document_type_id: employeeDocumentTypeId,
                },
            })
            .then(ForceDownload)
            .catch(err => toast.push(<Notification title={"Impossibile avviare il download"}
                                                   type='danger'/>))
            .finally(() => setLoading(false));
    };

    return (
        <Button
            type='button'
            disabled={disabled}
            loading={loading}
            size={size}
            variant='solid'
            color='sky-800'
            icon={<HiFolderDownload/>}
            onClick={() => handleDownload()}
        >
            {buttonText}
        </Button>
    );
};

export default DownloadZipButton;
