import {Form, Formik} from "formik";
import React, {useContext, useEffect, useState} from "react";
import companyEmploymentFormConfig from "../_components/companyEmploymentFormConfig";
import employmentsService from "../../../../../../../../services/employmentsService";
import {Card, Notification, toast} from "../../../../../../../../components/ui";
import FormDescription from "../../../../../../../../components/RefactoredForms/FormDescription";
import EmploymentForm from "../_components/EmploymentForm";
import CancelButton from "../../../../../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../../../../../components/ui/Buttons/_components/SaveButton";
import {CompanyEmployeeContext} from "../CompanyEmployeeShow";
import {useNavigate} from "react-router-dom";


const CompanyEmployeeInfo = () => {
    const {employment} = useContext(CompanyEmployeeContext);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState(companyEmploymentFormConfig.initialValues);

    useEffect(() => {
        if (employment) {
            setInitialValues(companyEmploymentFormConfig.setInitialValues(employment));
        }
    }, [employment]);


    // const handleDelete = () => {
    //     attachmentService
    //         .companyDestroy(company.uuid, attachment.uuid)
    //         .then(() => {
    //             toast.push(<Notification title='Allegato eliminato con successo' type='success' />);
    //             navigate("../");
    //         })
    //         .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />));
    // };


    if (!employment)
        return null;

    return (
        <Card>
            <Formik
                validationSchema={companyEmploymentFormConfig.validationSchema}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(true);
                    employmentsService
                        .update(employment.uuid, {
                            ...values,
                            domicile_address: !values.domicile_address_like_residence ? values.domicile_address : null,
                            status: values.status.value,
                            contract_type: values.contract_type.value,
                            working_time_type: values.working_time_type.value,
                            hire_date: values.hire_date ? values.hire_date.toISOString() : null,
                            contract_end_date: values.contract_end_date ? values.contract_end_date.toISOString() : null,
                            termination_date: values.termination_date ? values.termination_date.toISOString() : null,
                        })
                        .then(res => {
                            toast.push(<Notification title='Impiego aggiornato con successo'
                                                     type='success'/>)
                            // navigate("../../");
                        })
                        .catch(err => toast.push(<Notification
                            title={err?.response?.data?.message}
                            type='danger'/>))
                        .finally(() => setSubmitting(false));
                    setSubmitting(false);
                }}
            >
                {({isValid, isSubmitting, resetForm}) => (<Form>
                    <div className='flex flex-col gap-4'>
                        <FormDescription title='Modifica impiego'/>
                        <EmploymentForm/>
                        <div className='gap-4 flex justify-end'>
                            <CancelButton label={"Annulla"}
                                          handleClick={() => navigate("../")}/>
                            <CancelButton handleClick={() => resetForm()}/>
                            <SaveButton loading={isSubmitting} disabled={!isValid}/>
                        </div>
                    </div>
                </Form>)}
            </Formik>
        </Card>
    );
};

export default CompanyEmployeeInfo;
