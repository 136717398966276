import React, {useContext} from "react";
import {SupervisedEmployeeContext} from "../SupervisedEmployeesShow";
import {Card} from "../../../../../../../components/ui";
import EmployeeDocumentNavigator
    from "../../../../../../../components/ui/EmployeeDocumentNavigator/EmployeeDocumentNavigator";
import {useSanctum} from "react-sanctum";
import SupervisedEmployeeDeniedCardAlert from "../_components/SupervisedEmployeeDeniedCardAlert";
import usePermissions from "../../../../../../../utils/hooks/usePermissions";
import {Navigate} from "react-router-dom";
import {SupervisedContext} from "../../../SupervisedShow";


const SupervisedEmployeeDocuments = () => {
    const {user} = useSanctum();
    const {company} = useContext(SupervisedContext);

    const canViewEmployeeDocuments = company.company_settings?.is_employee_documents_active;
    const {employment} = useContext(SupervisedEmployeeContext);

    const hasPermission = usePermissions(user.role.permissions, ["employee_documents.view"]);

    if (!canViewEmployeeDocuments)
        return (<Card>
            <SupervisedEmployeeDeniedCardAlert
                message="Il servizio documenti dipendenti non è attivo."
            />
        </Card>)

    if (!hasPermission) {
        return <Navigate to='../info'/>;
    }

    return <Card>
        <EmployeeDocumentNavigator
            employment={employment}
            company={company}
            isAdmin={false}
        />
    </Card>;
};

export default SupervisedEmployeeDocuments;
