import React, {useContext} from 'react';
import {HiUser} from "react-icons/hi";
import {Card} from "../../../../../components/ui";
import PageHeader from "../../../../../components/ui/Page/PageHeader";
import {EmployeeContext} from "../EmployeeShow";

const EmployeeCard = () => {
    const {employment} = useContext(EmployeeContext);
    const employee = employment?.employee;

    return (

        <Card className='flex items-center justify-start flex-1'>
            <PageHeader
                title={employee?.first_name + ' ' + employee?.last_name}
                description={employee?.email}
                icon={<HiUser className='text-neutral-300' size={30}/>}
            />
        </Card>
    );
};

export default EmployeeCard;
