import {useRequest} from "ahooks";
import React, {createContext} from "react";
import {Link, Navigate, Outlet, useNavigate, useParams} from "react-router-dom";
import {Card} from "../../../../components/ui";
import employmentsService from "../../../../services/employmentsService";
import {cn} from "../../../../utils/classHelpers";
import EmployeeCard from "./_components/EmployeeCard";
import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import {useSanctum} from "react-sanctum";
import EmployeeDeniedCardAlert from "./_components/EmployeeDeniedCardAlert";
import usePermissions from "../../../../utils/hooks/usePermissions";

export const EmployeeContext = createContext({
    loading: true,
    employment: {},
});

const EmployeeShow = () => {
        const {id} = useParams();
        const navigate = useNavigate();
        const {user} = useSanctum();

        const {
            data: employment, loading
        } = useRequest(employmentsService.fetch, {defaultParams: [id]});

        const canViewEmployeeDetails = user.company.company_settings?.is_employee_detail_active;
        const hasPermission = usePermissions(user.role.permissions, ["employments.view"]);


        if (!canViewEmployeeDetails)
            return (
                <PageContainer>
                    <Card>
                        <EmployeeDeniedCardAlert
                            message="Il servizio dettaglio dipendenti non è attivo."
                        />
                    </Card>
                </PageContainer>
            )

        if (!hasPermission) {
            return <Navigate to='/'/>;
        }

        if (!employment) return null;

        const contextValue = {
            employment: employment, loading,
        };

        const location = window.location.pathname;

        return (
            <EmployeeContext.Provider value={contextValue}>
                <PageContainer>
                    <div className="space-y-2">
                        <div className="grid grid-cols-2 gap-4">
                            <EmployeeCard/>
                            <Card>
                                <div className="menu space-y-2">
                                    <div
                                        className={cn("menu-item hover:bg-sky-50", location.includes('info') ? 'bg-sky-50' : '')}>
                                        <Link to={`info`}
                                              className="block px-4 py-2 text-sm text-sky-800">Informazioni</Link>
                                    </div>
                                    <div
                                        className={cn("menu-item hover:bg-sky-50", location.includes('documents') ? 'bg-sky-50' : '')}>
                                        <Link to={`documents`}
                                              className="block px-4 py-2 text-sm text-sky-800">Documenti</Link>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <Outlet/>
                    </div>
                </PageContainer>
            </EmployeeContext.Provider>

        );
    }
;

export default EmployeeShow;
