import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {Card} from "../../../../../../../components/ui";
import FormDescription from "../../../../../../../components/RefactoredForms/FormDescription";
import FormRowDisplay from "../../../../../../../components/ui/Form/FormRow/FormRowDisplay";
import DateFormatDisplay from "../../../../../../../components/ui/Formatter/DateFormatDisplay";
import StateDisplay from "../../../../../../../components/ui/State/StateDisplay";
import AddressDisplay from "../../../../../../../components/ui/State/AddressDisplay";
import {SupervisedEmployeeContext} from "../SupervisedEmployeesShow";


const SupervisedEmployeeInfo = () => {
    const {employment} = useContext(SupervisedEmployeeContext);
    const navigate = useNavigate();

    if (!employment)
        return null;

    return (
        <Card>
            <FormDescription title='Informazioni generali'/>
            <FormRowDisplay label='Stato'><StateDisplay
                state={employment?.status}/></FormRowDisplay>
            <FormRowDisplay label='IBAN'>{employment?.iban ?? '--'}</FormRowDisplay>

            <FormDescription title='Indirizzi' className="mt-3"/>
            <FormRowDisplay
                label='Indirizzo di residenza'>
                <AddressDisplay address={employment?.residence_address}/>
            </FormRowDisplay>

            <FormRowDisplay
                label='Indirizzo di residenza'>
                {employment?.domicile_address ?
                    <AddressDisplay address={employment?.residence_address}/>
                    : 'Usa lo stesso indirizzo di residenza'}
            </FormRowDisplay>

            <FormDescription title='Contatto' className="mt-3"/>
            <FormRowDisplay
                label='Tipo contratto'>{employment?.contract_type?.label}</FormRowDisplay>
            {
                employment?.contract_type?.value === 'other' &&
                <FormRowDisplay
                    label='Altro tipo contratto'>{employment?.contract_type_other}</FormRowDisplay>
            }
            <FormRowDisplay
                label='Tipo orario'>{employment?.working_time_type?.label}</FormRowDisplay>

            {
                employment?.working_time_type?.value === 'part_time' &&
                <>
                    <FormRowDisplay
                        label='Percentuale'>{employment?.working_time_percentage}</FormRowDisplay>
                    <FormRowDisplay
                        label='Ore settimanali'>{employment?.working_time_other}</FormRowDisplay>
                </>
            }

            <FormRowDisplay label='Data assunzione'>
                <DateFormatDisplay value={employment?.hire_date}/>
            </FormRowDisplay>
            <FormRowDisplay label='Scadenza contratto'>
                <DateFormatDisplay value={employment?.contract_end_date}/>
            </FormRowDisplay>
            <FormRowDisplay label='Data cessazione'>
                <DateFormatDisplay value={employment?.termination_date}/>
            </FormRowDisplay>
            <FormRowDisplay label='N. proroghe'>{employment?.extension_count}</FormRowDisplay>
            <FormRowDisplay label='N. rinnovi'>{employment?.renewal_count}</FormRowDisplay>
            <FormRowDisplay label='CCNL applicato'>{employment?.applied_ccnl}</FormRowDisplay>
            <FormRowDisplay label='Livello'>{employment?.level}</FormRowDisplay>


            <FormDescription title='Note' className="mt-3"/>
            <FormRowDisplay label='Note'>{employment?.public_notes}</FormRowDisplay>
        </Card>
    )
        ;
};

export default SupervisedEmployeeInfo;
