import React, {useContext} from "react";
import {Card} from "../../../../../../../../components/ui";
import {CompanyEmployeeContext} from "../CompanyEmployeeShow";
import EmployeeDocumentNavigator
    from "../../../../../../../../components/ui/EmployeeDocumentNavigator/EmployeeDocumentNavigator";
import {CompanyContext} from "../../../Company";


const CompanyEmployeeDocuments = () => {
    const {employment} = useContext(CompanyEmployeeContext);
    const {company} = useContext(CompanyContext);

    return <Card>
        <EmployeeDocumentNavigator
            employment={employment}
            company={company}
            isAdmin={true}/>
    </Card>;
};

export default CompanyEmployeeDocuments;
