import {HiPencil} from "@react-icons/all-files/hi/HiPencil";
import React from "react";

import Button from "../Button";

const EditButton = ({
                        disabled,
                        loading,
                        label,
                        handleClick,
                        icon,
                        size = 'sm',
                        onlyIcon = false
                    }) => {
    return (
        <Button
            type='button'
            disabled={disabled}
            loading={loading}
            size={size}
            variant='solid'
            color='sky-800'
            icon={icon ? icon : <HiPencil/>}
            onClick={handleClick}
        >
            {!onlyIcon && (label || "Modifica")}
        </Button>
    );
};

export default EditButton;
