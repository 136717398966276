import {useRequest} from "ahooks";
import React from "react";
import {useNavigate} from "react-router-dom";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import {Card} from "../../../../components/ui";
import LaravelTable from "../../../../components/ui/LaravelTable/LaravelTable";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import employmentsService from "../../../../services/employmentsService";
import {employmentsCols} from "./_components/employmentsCols";
import {useSanctum} from "react-sanctum";
import EmployeeDeniedCardAlert from "./_components/EmployeeDeniedCardAlert";

const EmployeeIndex = () => {
    const {data: employees, run, loading} = useRequest(employmentsService.fetchAll, {manual: true});
    const navigate = useNavigate();
    const {user} = useSanctum()
    const canViewEmployeeList = user.company.company_settings?.is_employee_list_active;

    return (
        <PageContainer>
            <Card
                header={
                    <PageHeader
                        title='Dipendenti'
                        description='Qui puoi visualizzare tutti i dipendenti della tua azienda.'
                        loading={loading}
                    />
                }
            >
                {canViewEmployeeList ? (
                    <LaravelTable
                        columns={employmentsCols}
                        records={employees}
                        loading={loading}
                        onParamsChange={run}
                        searchable
                        sortable/>
                ) : <EmployeeDeniedCardAlert
                    message="Il servizio dipendenti non è attivo."
                />}

            </Card>
        </PageContainer>
    );
};

export default EmployeeIndex;
