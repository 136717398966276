import {useRequest} from "ahooks";
import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {ForceDownload} from "../../../../utils/files";
import {Button, Notification, toast} from "../../index";
import EmployeeDocumentTypeGroup from "./EmployeeDocumentTypeGroup";
import employeeDocumentService from "../../../../services/employeeDocumentService";

const DocumentCategoryFiles = ({employmentId, year, month, category}) => {
    const {
        loading,
        data: documents,
        runAsync,
        mutate
    } = useRequest(employeeDocumentService.fetchAll, {manual: true});

    const [searchParams, setSearchParams] = useSearchParams();
    const uploadId = searchParams.get("upload");

    useEffect(() => {
        runAsync({
            employment_id: employmentId,
            per_page: -1,
            filter: {
                document_category_id: category?.id,
                year: year?.value || null,
                month: month?.value || null,
            },
        }).catch(err => toast.push(<Notification title={err.response.data.message}
                                                 type='danger'/>));
    }, []);

    const handleDownload = (employeeDocumentTypeId = undefined) => {
        employeeDocumentService
            .downloadZip({
                employment_id: employmentId,
                per_page: -1,
                filter: {
                    document_category_id: category?.id,
                    year: year?.value || null,
                    month: month?.value || null,
                    employee_document_type_id: employeeDocumentTypeId,
                },
            })
            .then(ForceDownload)
            .catch(err => toast.push(<Notification title={"Impossibile avviare il download"}
                                                   type='danger'/>));
    };

    const showAllFiles = () => {
        searchParams.delete("upload");
        setSearchParams(searchParams);
    };

    return (
        <div>
            {uploadId && (
                <div
                    className='bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-4 my-6 shadow-md'
                    role='alert'>
                    <div className='flex items-center'>
                        <div className='py-1'>
                            <svg className='fill-current h-6 w-6 text-teal-500 mr-4'
                                 xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
                                <path
                                    d='M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z'/>
                            </svg>
                        </div>
                        <div>
                            <p className='text-lg font-bold'>Attenzione</p>
                            <p className='text-lg'>I documenti evidenziati sono quelli selezionati
                                in dashboard.</p>
                        </div>
                        <div className='ml-auto'>
                            <Button
                                type='button'
                                size='sm'
                                variant='twoTone'
                                color='teal-900'
                                onClick={() => showAllFiles()}
                                className='border-teal-500 border-b-4'
                            >
                                Visualizza tutti i file
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            {!loading &&
                category?.employee_document_types.map((employeeDocumentType, index) => (
                    <EmployeeDocumentTypeGroup
                        key={index}
                        year={year}
                        month={month}
                        employmentId={employmentId}
                        employeeDocumentType={employeeDocumentType}
                        documentCategory={category}
                        onDocumentAdded={newDocumentAdded => mutate([...documents, newDocumentAdded])}
                        onDocumentDeleted={documentUuid => mutate(documents.filter(d => d.uuid !== documentUuid))}
                        documents={(documents || []).filter(d => d.employee_document_type.id === employeeDocumentType.id)}
                        handleDownload={() => handleDownload(employeeDocumentType.id)}
                        uploadId={uploadId}
                    />
                ))}
        </div>
    );
};

export default DocumentCategoryFiles;
