import React, {useContext} from "react";
import {SupervisedContext} from "../SupervisedShow";
import BaseCompanyContacts from "../../../../../components/ui/Company/BaseCompanyContacts";
import BaseCompanyEmails from "../../../../../components/ui/Company/BaseCompanyEmails";
import CompanyPublicLinks from "../../../../../components/ui/Company/CompanyPublicLinks";

const SupervisedEmployees = () => {
    const {company, loading} = useContext(SupervisedContext);

    if (!company) return null;

    return <div>
        <div className='flex flex-row flex-wrap justify-center gap-4 w-full'>
            <BaseCompanyContacts company={company} loading={loading}/>
            <BaseCompanyEmails company={company} loading={loading}/>
            <CompanyPublicLinks company={company} loading={loading}/>
        </div>
    </div>;
};

export default SupervisedEmployees;
