import {useRequest} from "ahooks";
import {join} from "lodash";
import React, {useEffect, useState} from "react";
import {HiChevronRight} from "react-icons/hi";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";

import documentCategoryService from "../../../../services/documentCategoryService";
import dateHelper from "../../../../utils/dateHelper";
import Alert from "../../Alert";
import DocumentCategoryFiles from "./DocumentCategoryFiles";
import Folder from "./Folder";
import DownloadZipButton from "./DownloadZipButton";

const pastYearNumber = process.env.REACT_APP_COMPANY_PAST_YEAR_LIMIT ?? 0
const futureYearNumber = process.env.REACT_APP_COMPANY_FUTURE_YEAR_LIMIT ?? 0

const DocumentCategoryFolders = ({employmentId, isAdmin, canViewAllYears = false}) => {
    const {cat, num_a, num_b} = useParams();
    const navigate = useNavigate();
    const {pathname} = useLocation();

    const basePath = pathname.substring(0, pathname.lastIndexOf("documents") + "documents".length);

    const {
        loading,
        data: documentCategory,
        run,
    } = useRequest(documentCategoryService.fetch, {
        manual: true,
    });

    const availableYears = isAdmin || canViewAllYears
        ? dateHelper.getAllYears()
        : dateHelper.getYears();

    const availableMonths = dateHelper.getMonths();

    const [currentParams, setCurrentParams] = useState({
        category: documentCategory,
        year: null,
        month: null,
        showFolders: false,
        showFiles: false,
        folders: [],
        error: "",
    });

    useEffect(() => {
        run(cat, {params: {include: ["employeeDocumentTypes"]}});
    }, []);

    useEffect(() => {
        if (!loading && documentCategory) {
            if (num_a === undefined && num_b === undefined) {
                setCurrentParams({
                    ...currentParams,
                    category: documentCategory,
                    year: null,
                    month: null,
                    showFolders: documentCategory.is_split_by_year || documentCategory.is_split_by_month,
                    showFiles: !documentCategory.is_split_by_year && !documentCategory.is_split_by_month,
                    folders:
                        !documentCategory.is_split_by_year && !documentCategory.is_split_by_month
                            ? []
                            : documentCategory.is_split_by_year
                                ? availableYears
                                : availableMonths,
                    error: "",
                });
            } else if (!!num_a && num_b === undefined) {
                if (documentCategory.is_split_by_year) {
                    const validYear = availableYears.find(ay => ay.value === parseInt(num_a));
                    setCurrentParams({
                        ...currentParams,
                        category: documentCategory,
                        year: validYear || null,
                        month: null,
                        showFolders: documentCategory.is_split_by_month,
                        showFiles: !documentCategory.is_split_by_month,
                        folders: validYear && documentCategory.is_split_by_month ? availableMonths : [],
                        error: validYear ? "" : "Anno non valido",
                    });
                } else if (documentCategory.is_split_by_month) {
                    const validMonth = availableMonths.find(ay => ay.value === parseInt(num_a));
                    setCurrentParams({
                        ...currentParams,
                        category: documentCategory,
                        year: null,
                        month: validMonth || null,
                        showFolders: validMonth ? false : [],
                        showFiles: !!validMonth,
                        folders: [],
                        error: validMonth ? "" : "Mese non valido",
                    });
                } else {
                }
            } else if (!!num_a && !!num_b) {
                const validYear = availableYears.find(ay => ay.value === parseInt(num_a));
                const validMonth = availableMonths.find(ay => ay.value === parseInt(num_b));
                setCurrentParams({
                    ...currentParams,
                    category: documentCategory,
                    year: validYear || null,
                    month: validMonth || null,
                    showFolders: !!validMonth || !!validMonth,
                    showFiles: !!validYear && !!validMonth,
                    folders: [],
                    error: !!validYear && !!validMonth ? "" : "L'anno ed il mese corrente non sono al momento visualizzabili. Contatta lo Studio per maggiori informazioni.",
                });
            }
        }
    }, [loading, num_a, num_b, documentCategory]);

    const breadCrumbClass =
        "hover:underline hover:text-sky-500 last:text-gray-500 last:hover:text-gray-500 last:hover:no-underline last:pointer-events-none";

    return (
        <div>
            <div className='flex items-center justify-between gap-2 min-h-[25px]'>
                <div>
                    <div className='flex items-center justify-start gap-2 font-bold min-h-[40px]'>
                        <Link to={pathname.substring(0, pathname.lastIndexOf("/"))}
                              className='text-sky-800 hover:text-sky-500'>
                            ↑
                        </Link>
                        <Link className='document-breadcrumb-item' to={basePath}>
                            Categorie
                        </Link>

                        {currentParams.category?.name && (
                            <>
                                <HiChevronRight/>
                                <Link className='document-breadcrumb-item'
                                      to={join([basePath, currentParams.category?.uuid], "/")}>
                                    {currentParams.category?.name}
                                </Link>
                            </>
                        )}

                        {currentParams.year?.label && (
                            <>
                                <HiChevronRight/>
                                <Link
                                    className='document-breadcrumb-item'
                                    to={join([basePath, currentParams.category?.uuid, currentParams.year?.value], "/")}
                                >
                                    {currentParams.year?.label}
                                </Link>
                            </>
                        )}

                        {currentParams.month?.label && (
                            <>
                                <HiChevronRight/>
                                <Link
                                    className='document-breadcrumb-item'
                                    to={join([basePath, currentParams.category?.uuid, currentParams.year?.value, currentParams.month?.value], "/")}
                                >
                                    {currentParams.month?.label}
                                </Link>
                            </>
                        )}
                    </div>
                </div>

                <div>
                    <DownloadZipButton
                        employmentId={employmentId}
                        documentCategoryId={documentCategory?.id}
                        year={currentParams.year?.value}
                        month={currentParams.month?.value}
                        buttonText={"Scarica TUTTO"}
                    />
                </div>
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                {currentParams.error && (
                    <Alert showIcon={true} type='warning'>
                        {currentParams.error}
                    </Alert>
                )}

                {currentParams.showFolders &&
                    currentParams.folders.map((folder, index) => (
                        <Folder key={index} label={folder.label}
                                onSelect={() => navigate(`${pathname}/${folder.value}`)}/>
                    ))}
            </div>

            {currentParams.showFiles && (
                <DocumentCategoryFiles
                    employmentId={employmentId}
                    year={currentParams.year}
                    month={currentParams.month}
                    category={currentParams.category}
                />
            )}
        </div>
    );
};

export default DocumentCategoryFolders;
