import {HiClipboardList} from "@react-icons/all-files/hi/HiClipboardList";
import React from "react";
import {useSanctum} from "react-sanctum";
import Alert from "../../Alert";
import DownloadZipButton from "../../Buttons/_components/DownloadZipButton";
import DisplayTitle from "../../Displays/DisplayTitle";
import Document from "./Document";
import DocumentUploader from "./DocumentUploader";
import {cn} from "../../../../utils/classHelpers";
import employeeDocumentService from "../../../../services/employeeDocumentService";

const EmployeeDocumentTypeGroup = ({
                                       handleDownload,
                                       employeeDocumentType,
                                       documentCategory,
                                       employmentId,
                                       onDocumentAdded,
                                       onDocumentDeleted,
                                       documents,
                                       year,
                                       month,
                                       uploadId,
                                   }) => {
    const {user} = useSanctum();

    return (
        <div
            className={cn('py-8 border-b border-gray-200 dark:border-gray-600', {
                'bg-red-100': employeeDocumentType.is_visible_only_to_sysadmin
            })}>
            <div className='grid md:grid-cols-3 gap-4 '>
                <div className='col-span-1'>
                    <DisplayTitle
                        title={employeeDocumentType.name}
                        description={employeeDocumentType.description}
                        icon={<HiClipboardList className='text-neutral-300' size={45}/>}
                        info={
                            documents.length > 0 && (
                                <div className='mt-1'>
                                    <DownloadZipButton
                                        disabled={documents.length < 1}
                                        employmentId={employmentId}
                                        documentCategoryId={documentCategory?.id}
                                        year={year}
                                        month={month}
                                        employeeDocumentType={employeeDocumentType?.id}
                                        size='xs'
                                        buttonText={"Scarica " + employeeDocumentType.name}
                                    />
                                </div>
                            )
                        }
                    />
                    {(employeeDocumentType.can_be_uploaded_by_company || user?.is_system_admin) && (
                        <DocumentUploader
                            onDocumentAdded={onDocumentAdded}
                            employeeDocumentType={employeeDocumentType}
                            employmentId={employmentId}
                            year={year}
                            month={month}
                        />
                    )}
                </div>
                <div className='col-span-2'>
                    {documents.length < 1 ? (
                        <div className='py-4'>
                            <Alert type='info' showIcon={true}>
                                Nessun documento
                            </Alert>
                        </div>
                    ) : (
                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                            {employeeDocumentService.reorderDocumentsByMediaFileName(documents).map((document, index) => (
                                <Document key={index} document={document}
                                          onDocumentDeleted={onDocumentDeleted}
                                          uploadId={uploadId}/>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EmployeeDocumentTypeGroup;
