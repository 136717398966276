import React, {useContext} from "react";
import {SupervisedContext} from "../../SupervisedShow";
import {Link} from "react-router-dom";
import StateDisplay from "../../../../../../components/ui/State/StateDisplay";
import DateFormatDisplay from "../../../../../../components/ui/Formatter/DateFormatDisplay";
import {useRequest} from "ahooks";
import employmentsService from "../../../../../../services/employmentsService";
import LaravelTable from "../../../../../../components/ui/LaravelTable/LaravelTable";
import {Card} from "../../../../../../components/ui";
import SupervisedEmployeeDeniedCardAlert from "./_components/SupervisedEmployeeDeniedCardAlert";


const tableCols = [
    {
        header: "Impiegato",
        accessorKey: "employee",
        cell: ({getValue, row: {original}}) =>
            <Link to={original.uuid} className='font-bold'>
                {getValue().last_name} {getValue().first_name}
            </Link>
    },
    {
        header: "Stato",
        accessorKey: "status",
        cell: ({getValue}) => <StateDisplay state={getValue()}/>,
    },
    {
        header: "Data di assunzione",
        accessorKey: "hire_date",
        cell: ({getValue}) => <DateFormatDisplay value={getValue()}/>
    },
    {
        header: "Data di cessazione",
        accessorKey: "termination_date",
        cell: ({getValue}) => <DateFormatDisplay value={getValue()}/>
    },

];

const SupervisedEmployeesIndex = () => {
    const {company} = useContext(SupervisedContext);
    const canViewEmployeeList = company.company_settings?.is_employee_list_active;

    const {
        data: employments,
        run,
        loading,
    } = useRequest(employmentsService.fetchAll, {
        manual: true,
        defaultParams: [company?.id],
    });


    const handleRun = params => {
        if (company?.id) {
            run({...params, company_id: company.id});
        }
    };

    return (
        <Card>

            {canViewEmployeeList ? (
                    <LaravelTable columns={tableCols} records={employments} loading={loading}
                                  onParamsChange={handleRun} searchable sortable/>) :
                <SupervisedEmployeeDeniedCardAlert
                    message="Il servizio dipendenti non è attivo per questa azienda."
                />}
        </Card>
    );

};

export default SupervisedEmployeesIndex;
