import React from "react";
import {useParams} from "react-router-dom";

import DocumentCategoryFolders from "./_components/DocumentCategoryFolders";
import DocumentCategoryNavigator from "./_components/DocumentCategoryNavigator";

const EmployeeDocumentNavigator = ({company, employment, isAdmin = false}) => {
    const {cat} = useParams();

    return !!cat ? (
        <DocumentCategoryFolders
            isAdmin={isAdmin}
            employmentId={employment?.id}
            canViewAllYears={company.company_settings?.is_all_year_active ?? false}
        />
    ) : (
        <DocumentCategoryNavigator/>
    );
};

export default EmployeeDocumentNavigator;
