import { useRequest } from "ahooks";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import documentService from "../../../../services/documentService";
import { ForceDownload } from "../../../../utils/files";
import { Button, Notification, toast } from "../../index";
import DocumentTypeGroup from "./DocumentTypeGroup";

const DocumentCategoryFiles = ({ companyId, year, month, category }) => {
    const { loading, data: documents, runAsync, mutate } = useRequest(documentService.fetchAll, { manual: true });

    const [searchParams, setSearchParams] = useSearchParams();
    const uploadId = searchParams.get("upload");

    useEffect(() => {
        runAsync({
            company_id: companyId,
            per_page: -1,
            filter: {
                document_category_id: category?.id,
                year: year?.value || null,
                month: month?.value || null,
            },
        }).catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />));
    }, []);

    const handleDownload = (documentTypeId = undefined) => {
        documentService
            .downloadZip({
                company_id: companyId,
                per_page: -1,
                filter: {
                    document_category_id: category?.id,
                    year: year?.value || null,
                    month: month?.value || null,
                    document_type_id: documentTypeId,
                },
            })
            .then(ForceDownload)
            .catch(err => toast.push(<Notification title={"Impossibile avviare il download"} type='danger' />));
    };

    const showAllFiles = () => {
        searchParams.delete("upload");
        setSearchParams(searchParams);
    };

    return (
        <div>
            {uploadId && (
                <div className='bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-4 my-6 shadow-md' role='alert'>
                    <div className='flex items-center'>
                        <div className='py-1'>
                            <svg className='fill-current h-6 w-6 text-teal-500 mr-4' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
                                <path d='M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z' />
                            </svg>
                        </div>
                        <div>
                            <p className='text-lg font-bold'>Attenzione</p>
                            <p className='text-lg'>I documenti evidenziati sono quelli selezionati in dashboard.</p>
                        </div>
                        <div className='ml-auto'>
                            <Button
                                type='button'
                                size='sm'
                                variant='twoTone'
                                color='teal-900'
                                onClick={() => showAllFiles()}
                                className='border-teal-500 border-b-4'
                            >
                                Visualizza tutti i file
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            {!loading &&
                category?.document_types.map((documentType, index) => (
                    <DocumentTypeGroup
                        key={index}
                        year={year}
                        month={month}
                        companyId={companyId}
                        documentType={documentType}
                        documentCategory={category}
                        onDocumentAdded={newDocumentAdded => mutate([...documents, newDocumentAdded])}
                        onDocumentDeleted={documentUuid => mutate(documents.filter(d => d.uuid !== documentUuid))}
                        onDocumentUpdated={document => mutate(documents.map(d => (d.uuid === document.uuid ? document : d)))}
                        documents={(documents || []).filter(d => d.document_type.id === documentType.id)}
                        handleDownload={() => handleDownload(documentType.id)}
                        uploadId={uploadId}
                    />
                ))}
        </div>
    );
};

export default DocumentCategoryFiles;
