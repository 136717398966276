import React from 'react';
import {HiOutlineInformationCircle} from "react-icons/hi";

const EmployeeDeniedCardAlert = ({message}) => {

    return (
        <div className="flex items-center justify-center">
            <div className="p-6 text-center">
                <HiOutlineInformationCircle className="text-red-300 mx-auto" size={70}/>
                <h1 className="text-2xl text-neutral-500 font-semibold mt-4">{message}</h1>
                <p className="text-lg text-neutral-400 mt-2">
                    Per maggiori dettagli, contatta Studio Barzaghi.
                </p>
            </div>
        </div>
    );
};

export default EmployeeDeniedCardAlert;
