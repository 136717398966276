import React, {useContext} from "react";
import {EmployeeContext} from "../EmployeeShow";
import {Card} from "../../../../../components/ui";
import EmployeeDocumentNavigator
    from "../../../../../components/ui/EmployeeDocumentNavigator/EmployeeDocumentNavigator";
import {useSanctum} from "react-sanctum";
import EmployeeDeniedCardAlert from "../_components/EmployeeDeniedCardAlert";
import usePermissions from "../../../../../utils/hooks/usePermissions";
import {Navigate} from "react-router-dom";


const EmployeeDocuments = () => {
    const {user} = useSanctum();

    const canViewEmployeeDocuments = user.company.company_settings?.is_employee_documents_active;
    const {employment} = useContext(EmployeeContext);

    const hasPermission = usePermissions(user.role.permissions, ["employee_documents.view"]);

    if (!canViewEmployeeDocuments)
        return (<Card>
            <EmployeeDeniedCardAlert
                message="Il servizio documenti dipendenti non è attivo."
            />
        </Card>)

    if (!hasPermission) {
        return <Navigate to='../info'/>;
    }

    return <Card>
        <EmployeeDocumentNavigator
            employment={employment}
            company={user.company}
            isAdmin={false}
        />
    </Card>;
};

export default EmployeeDocuments;
