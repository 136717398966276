import {first} from "lodash";
import React, {useContext} from "react";
import {
    HiAdjustmentsVertical,
    HiBuildingOffice2,
    HiCircleStack,
    HiDocumentCheck,
    HiEye,
    HiFolderOpen,
    HiListBullet,
    HiMiniUserGroup,
    HiPaperClip,
    HiUsers
} from "react-icons/hi2";
import {matchRoutes, Outlet, useLocation, useNavigate} from "react-router-dom";
import {Tabs} from "../../../../../components/ui";
import TabList from "../../../../../components/ui/Tabs/TabList";
import TabNav from "../../../../../components/ui/Tabs/TabNav";
import {CompanyContext} from "./Company";
import {MdDashboard} from "react-icons/md";
import Card from "../../../../../components/ui/Card";
import BaseCompanyInfo from "../../../../../components/ui/Company/BaseCompanyInfo";

const CompanyShow = () => {
    const {company, loading} = useContext(CompanyContext);
    const navigate = useNavigate();
    const currentLocation = useLocation();

    const tabs = [
        {
            value: "dashboard",
            path: "/admin/companies/:id/dashboard/*",
            label: "Dashboard",
            description: "",
            icon: <MdDashboard/>,
            show: true,
        },
        {
            value: "info",
            path: "/admin/companies/:id/info/*",
            label: "Anagrafica",
            description: "",
            icon: <HiListBullet/>,
            show: true,
        },
        {
            value: "details",
            path: "/admin/companies/:id/details/*",
            label: "Dettaglio",
            description: "",
            icon: <HiCircleStack/>,
            show: true,
        },
        {
            value: "settings",
            path: "/admin/companies/:id/settings/*",
            label: "Impostazioni",
            description: "",
            icon: <HiAdjustmentsVertical/>,
            show: true,
        },
        {
            value: "offices",
            path: "/admin/companies/:id/offices/*",
            label: "Sedi",
            description: "",
            icon: <HiBuildingOffice2/>,
            show: true,
        },
        {
            value: "supervisions",
            path: "/admin/companies/:id/supervisions/*",
            label: "Professionista delegato",
            description: "",
            icon: <HiEye/>,
            show: true,
        },
        {
            value: "supervised",
            path: "/admin/companies/:id/supervised/*",
            label: "Aziende con delega",
            icon: <HiDocumentCheck/>,
            show: (company?.supervised_companies || []).length > 0,
        },
        {
            value: "users",
            path: "/admin/companies/:id/users/*",
            label: "Utenti",
            description: "",
            icon: <HiUsers/>,
            show: true,
        },
        {
            value: "attachments",
            path: "/admin/companies/:id/attachments/*",
            label: "Allegati",
            description: "",
            icon: <HiPaperClip/>,
            show: true,
        },
        {
            value: "documents",
            path: "/admin/companies/:id/documents/*",
            label: "Documenti",
            description: "",
            icon: <HiFolderOpen/>,
            show: true,
        },
        {
            value: "employees",
            path: "/admin/companies/:id/employees/*",
            label: "Dipendenti",
            description: "",
            icon: <HiMiniUserGroup/>,
            show: true,
        },
    ];

    const getCurrentMatch = () => first(matchRoutes(tabs, currentLocation)) || undefined;

    return (
        <div className='flex flex-col gap-4'>

            <Tabs value={getCurrentMatch()?.route?.value || ""}
                  variant="pill"
                  onChange={value => navigate(value)}>
                <div className="flex flex-row justify-start gap-4">
                    <div className="w-64 flex-shrink-0 space-y-2">
                        <BaseCompanyInfo company={company} loading={loading}/>
                        <Card bodyClass="p-2">
                            <TabList
                                className="flex flex-col items-start gap-2 p-0 m-0 overflow-hidden">
                                {tabs.map(
                                    (tab, index) =>
                                        tab.show && (
                                            <TabNav key={index} value={tab.value} icon={tab?.icon}
                                                    onClick={() => navigate(tab.value)}
                                                    className="w-full justify-start">
                                                {tab.label}
                                            </TabNav>
                                        )
                                )}
                            </TabList>
                        </Card>
                    </div>
                    <div className='flex-grow'>
                        <Outlet/>
                    </div>
                </div>
            </Tabs>

        </div>
    );
};

export default CompanyShow;
