import {TbArrowsSplit2} from "react-icons/tb";

import React, {useState} from "react";
import {Notification, toast, Tooltip} from "../../index";
import Button from "../Button";
import documentService from "../../../../services/documentService";


const cantSplitMessage = 'Non hai acquistato il servizio di divisione documenti. Contatta Studio Barzaghi per saperne di più.';

const SplitButton = ({
                         disabled,
                         documentId,
                         label,
                         size = "sm",
                         onSuccess,
                         alreadySplit = false,
                         cantSplit = false
                     }) => {
    const [loading, setLoading] = useState(false);

    const handleSplitDocument = () => {
        setLoading(true);
        documentService
            .splitDocument(documentId)
            .then((res) => {
                onSuccess(res);
                toast.push(<Notification
                    title='Il documento è stato suddiviso con successo'
                    type='success'/>);
            })
            .catch(err => toast.push(
                <Notification title={err.response.data.message} type='danger'/>)
            )
            .finally(() => setLoading(false));
    }

    const tooltip = cantSplit ? cantSplitMessage : (alreadySplit ? 'Documento già suddiviso' : `Dividi ${label}`);

    return (
        <Tooltip title={tooltip}>
            <Button
                type='button'
                disabled={disabled || alreadySplit || cantSplit}
                loading={loading}
                size={size}
                variant='solid'
                color='orange-400'
                icon={<TbArrowsSplit2/>}
                onClick={() => handleSplitDocument()}
            />
        </Tooltip>
    );
};

export default SplitButton;
