import React, {useContext} from "react";

import DocumentNavigator from "../../../../../components/ui/DocumentNavigator/DocumentNavigator";
import {SupervisedContext} from "../SupervisedShow";
import {Card} from "../../../../../components/ui";

const SupervisedDocuments = () => {
    const {company} = useContext(SupervisedContext);

    if (!company) return null;

    return <Card>
        <DocumentNavigator company={company} isAdmin={false}/>
    </Card>;
};

export default SupervisedDocuments;
