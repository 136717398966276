import React from "react";

const AddressDisplay = ({address}) => {
    if (address) {

        const addressList = [
            address.address + ', ' + address.street_number,
            address.postal_code + ' ' + address.city,
            address.country
        ]

        return <span>
        {addressList.join(' - ')}
        </span>
    }


    return <span> --- </span>;
};

export default AddressDisplay;
