import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";

import FormDescription from "../../../../../components/RefactoredForms/FormDescription";
import FormDisplay from "../../../../../components/RefactoredForms/FormDisplay";
import {Card} from "../../../../../components/ui";
import PageHeader from "../../../../../components/ui/Page/PageHeader";
import {MyCompanyUserContext} from "./User";
import MyCompanyUserCommonData from "./components/MyCompanyUserCommonData";
import MyCompanyUserDropdown from "./components/MyCompanyUserDropdown";
import {useRequest} from "ahooks";
import listService from "../../../../../services/listService";

const UserShow = () => {
    const navigate = useNavigate();

    const {
        user,
        canUpdateUser,
        setUser,
        canUserSeeAllDocumentTypes,
        canUserSeeAllEmployeeDocumentTypes
    } = useContext(MyCompanyUserContext);

    const {data: documentTypes} = useRequest(listService.publicDocumentTypes);
    const {data: employeeDocumentTypes} = useRequest(listService.publicEmployeeDocumentTypes);

    return (
        <>
            <Card
                header={
                    <PageHeader
                        title='Gestione utente'
                        toolbar={
                            <>
                                {canUpdateUser && (
                                    <div className='flex justify-end items-center gap-4'>
                                        <MyCompanyUserDropdown
                                            user={user}
                                            onToggleActivation={setUser}
                                            onReInvite={setUser}
                                            onDelete={() => navigate("../")}
                                        />
                                    </div>
                                )}
                            </>
                        }
                    />
                }
            >
                <div className='flex flex-col gap-4'>
                    <MyCompanyUserCommonData/>

                    <div className='grid xl:grid-cols-3 gap-4'>
                        <FormDisplay value='Nome e cognome'>{user?.name}</FormDisplay>
                        <FormDisplay value='Email'>{user?.email}</FormDisplay>
                        <FormDisplay value='Ruolo'>{user?.role?.name}</FormDisplay>
                    </div>
                    <FormDescription title={"Tipologie documento aziendali"}
                                     desc={"Tipologie documento aziendali alle quali l'utente ha accesso."}/>

                    {canUserSeeAllDocumentTypes ? (
                        <span className='font-semibold'>L'utente ha il permesso di vedere tutte le tipologie di documento</span>
                    ) : documentTypes?.length < 1 ? (
                        <>Nessuna tipologia di documento disponibile.</>
                    ) : (
                        <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                            {documentTypes?.filter(dt => user?.company_user?.document_types_permitted.includes(dt.value.toString()))
                                .map(dt => (
                                    <div key={dt.value} className='flex flex-col'>
                                        <span className='font-semibold'>{dt?.label}</span>
                                        <span className='text-xs'>{dt?.description}</span>
                                    </div>
                                ))}
                        </div>
                    )}

                    <hr/>

                    <FormDescription
                        title={"Tipologie documento dipendenti"}
                        desc={"Seleziona le tipologie documento dei dipendenti al quale l'utente ha accesso."}
                    />

                    {canUserSeeAllEmployeeDocumentTypes ? (
                        <span className='font-semibold'>L'utente ha il permesso di vedere tutte le tipologie di documento per i dipendenti</span>
                    ) : employeeDocumentTypes?.length < 1 ? (
                        <>Nessuna tipologia di documento disponibile.</>
                    ) : (
                        <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                            {employeeDocumentTypes?.filter(dt => user?.company_user?.employee_document_types_permitted.includes(dt.value.toString()))
                                .map(dt => (
                                    <div key={dt.value} className='flex flex-col'>
                                        <span className='font-semibold'>{dt?.label}</span>
                                        <span className='text-xs'>{dt?.description}</span>
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            </Card>
        </>
    );
};

export default UserShow;
