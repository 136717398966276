import {cn} from "../../../../utils/classHelpers";
import React, {useState} from "react";
import {HiDocumentText} from "react-icons/hi";
import {useSanctum} from "react-sanctum";

import documentService from "../../../../services/documentService";
import {DownloadOrPreview} from "../../../../utils/files";
import DestroyButton from "../../Buttons/_components/DestroyButton";
import DownloadButton from "../../Buttons/_components/DownloadButton";
import PreviewButton from "../../Buttons/_components/PreviewButton";
import DateFormatDisplay from "../../Formatter/DateFormatDisplay";
import Spinner from "../../Spinner";
import DocumentStateDisplay from "../../State/DocumentStateDisplay";
import {Notification, toast} from "../../index";
import SplitButton from "../../Buttons/_components/SplitButton";

const Document = ({document, onDocumentDeleted, onDocumentUpdated, uploadId}) => {
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [splitLoading, setSplitLoading] = useState(false);
    const {user} = useSanctum();

    const shouldBlur = uploadId && document.upload?.uuid !== uploadId;
    const shouldEmphasize = uploadId && document.upload?.uuid === uploadId;

    const handleDownload = () => {
        setLoading(true);
        documentService
            .download(document?.media?.url)
            .then(DownloadOrPreview)
            .catch(err => toast.push(<Notification title={"Impossibile avviare il download"}
                                                   type='danger'/>))
            .finally(() => setLoading(false));
    };

    const handleDelete = () => {
        setDeleteLoading(true);
        documentService
            .destroy(document?.uuid)
            .then(() => {
                onDocumentDeleted(document?.uuid);
                toast.push(<Notification title='Document eliminato con successo' type='success'/>);
            })
            .catch(err => toast.push(<Notification title={err.response.data.message}
                                                   type='danger'/>))
            .finally(() => setDeleteLoading(false));
    };

    const handleSplitDocument = () => {
        setSplitLoading(true);
        documentService
            .splitDocument(document?.uuid)
            .then(() => {
                onDocumentDeleted(document?.uuid);
                toast.push(<Notification title='Il documento è stato messo in coda di divisione'
                                         type='success'/>);
            })
            .catch(err => toast.push(
                <Notification title={err.response.data.message} type='danger'/>)
            )
            .finally(() => setSplitLoading(false));

    }

    const canCompanySplitDocument = user?.is_system_admin || user.company?.company_settings?.is_document_splitting_active

    const handleSuccessSplit = (newDocument) => {
        onDocumentUpdated(newDocument);
    }

    return (
        <div
            className={cn("flex items-center hover:bg-neutral-100 rounded-lg py-2 relative", {
                blur: shouldBlur,
                disabled: shouldBlur,
            })}
        >
            {shouldBlur &&
                <div className='absolute inset-0 flex justify-center items-center z-10'/>}
            <div>
                <HiDocumentText color={cn(shouldEmphasize ? "text-zinc-800" : "#d4d4d4")}
                                size={100}/>
                {user?.is_system_admin && (
                    <div className='text-center'>
                        <DocumentStateDisplay state={document.status}
                                              auto_publish_at={document.auto_publish_at}/>
                    </div>
                )}
            </div>
            <div className='flex flex-col gap-1 py-2'>
                <h6>{document.media?.name}</h6>
                <span>{document.media?.file_name}</span>
                <div className='flex flex-row gap-2'>
                    {document?.user?.name}
                    <span>|</span>
                    <DateFormatDisplay value={document?.updated_at}/>
                </div>
                {document.expiration && (
                    <span className='flex justify-start items-center gap-2'>
                        Scadenza:
                        <DateFormatDisplay value={document.expiration} showExpiration={true}/>
                    </span>
                )}

                <div className='flex gap-1'>
                    <DownloadButton size='xs' url={document?.media?.url}
                                    label={document.media?.file_name}
                                    filename={document?.media?.file_name}/>
                    <PreviewButton size='xs' url={document?.media?.url}
                                   label={document.media?.file_name}
                                   filename={document?.media?.file_name}/>
                    {document.can_be_split && (
                        <SplitButton
                            onSuccess={handleSuccessSplit}
                            documentId={document?.uuid}
                            alreadySplit={document.is_already_split}
                            cantSplit={!canCompanySplitDocument}
                            size='xs'
                            label={document.media?.file_name}/>
                    )}
                    {user?.is_system_admin && (
                        <DestroyButton
                            size='xs'
                            style={{width: "100px"}}
                            loading={deleteLoading}
                            message={`Sei sicuro di voler eliminare il documento ${document.media?.file_name}?`}
                            handleClick={() => handleDelete()}
                        />
                    )}
                </div>
                {loading && <Spinner color='#d4d4d4'/>}
            </div>
        </div>
    );
};

export default Document;
