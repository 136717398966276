import {Form, Formik} from "formik";
import React, {useContext} from "react";
import * as Yup from "yup";

import FormCheckbox from "../../../../../../../components/RefactoredForms/FormCheckbox";
import FormDescription from "../../../../../../../components/RefactoredForms/FormDescription";
import FormGroup from "../../../../../../../components/RefactoredForms/FormGroup";
import {Card, Notification, toast} from "../../../../../../../components/ui";
import CancelButton from "../../../../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../../../../components/ui/Buttons/_components/SaveButton";
import companyService from "../../../../../../../services/companyService";
import {companyFormConfig} from "../../../_components/companyFormConfig";
import {CompanyContext} from "../../Company";

const CompanySettingsTab = () => {
    const {company, setCompany, loading} = useContext(CompanyContext);
    // const [initialValues, setInitialValues] = useState(companyFormConfig.companySettingsInitialValues);
    //
    // useEffect(() => {
    //     if (!loading) {
    //         setInitialValues(company.company_settings);
    //     }
    // }, [company]);


    const initialValues = {
        ...companyFormConfig.companySettingsInitialValues,
        ...company.company_settings
    }


    return (
        <Card>
            <Formik
                validationSchema={Yup.object().shape(companyFormConfig.companySettingsValidationSchema)}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting}) => {
                    companyService
                        .updateSettings(company.uuid, values)
                        .then(res => {
                            toast.push(<Notification
                                title='Impostazioni azienda aggiornate con successo'
                                type='success'/>);
                            setCompany({...company, company_settings: res});
                        })
                        .catch(err => toast.push(<Notification title={err.response.data.message}
                                                               type='danger'/>))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({isValid, isSubmitting, resetForm, dirty, values}) => (
                    <Form>
                        <div className='flex flex-col gap-4'>
                            <FormDescription title='Impostazioni'/>
                            <FormGroup required label='' name='is_all_year_active'>
                                <FormCheckbox label='Attiva visualizzazione di tutti gli anni'
                                              name='is_all_year_active'/>
                            </FormGroup>

                            <FormGroup required label='' name='is_employee_list_active'>
                                <FormCheckbox label='Attiva visualizzazione dipendenti'
                                              name='is_employee_list_active'/>
                            </FormGroup>

                            <FormGroup required label='' name='is_employee_detail_active'>
                                <FormCheckbox label='Attiva visualizzazione dettagli dipendenti'
                                              name='is_employee_detail_active'/>
                            </FormGroup>

                            <FormGroup required label='' name='is_employee_documents_active'>
                                <FormCheckbox label='Attiva visualizzazione documenti dipendenti'
                                              name='is_employee_documents_active'/>
                            </FormGroup>

                            <FormGroup required label='' name='is_document_splitting_active'>
                                <FormCheckbox label='Attiva possibilità di dividere i documenti nei vari dipendenti'
                                              name='is_document_splitting_active'/>
                            </FormGroup>

                            <FormGroup required label='' name='is_employees_panel_active'>
                                <FormCheckbox label="Attiva l'accesso al pannello dipendenti"
                                              name='is_employees_panel_active'/>
                            </FormGroup>

                            <div className='gap-4 flex justify-end'>
                                <CancelButton disabled={!isValid || isSubmitting}
                                              handleClick={() => resetForm()}/>
                                <SaveButton loading={isSubmitting}
                                            label="Salva impostazioni"
                                            disabled={!isValid || isSubmitting || !dirty}/>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Card>
    );
};

export default CompanySettingsTab;
