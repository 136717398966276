import Employees from "../../../../views/protected/myCompany/employees/Employees";
import React from "react";
import EmployeeIndex from "../../../../views/protected/myCompany/employees/EmployeesIndex";
import EmployeeShow from "../../../../views/protected/myCompany/employees/EmployeeShow";
import {Navigate} from "react-router-dom";
import EmployeeInfo from "../../../../views/protected/myCompany/employees/showTabs/EmployeeInfo";
import EmployeeDocuments
    from "../../../../views/protected/myCompany/employees/showTabs/EmployeeDocuments";

const employeesRoutes = {
    path: "employees",
    element: <Employees/>,
    children: [
        {
            path: "",
            element: <EmployeeIndex/>,
        },
        {
            path: ":id",
            element: <EmployeeShow/>,
            children: [
                {
                    path: "",
                    element: <Navigate to='info'/>,
                },
                {
                    path: "info",
                    element: <EmployeeInfo/>,
                },
                {
                    path: "documents",
                    element: <EmployeeDocuments/>,
                    children: [
                        {
                            path: ":cat",
                            element: <EmployeeDocuments/>,
                        },
                        {
                            path: ":cat/:num_a",
                            element: <EmployeeDocuments/>,
                        },
                        {
                            path: ":cat/:num_a/:num_b",
                            element: <EmployeeDocuments/>,
                        },
                    ],
                },
            ],
        },
    ],
};


export default employeesRoutes;
