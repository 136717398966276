import { HiBookOpen } from "@react-icons/all-files/hi/HiBookOpen";
import { HiKey } from "@react-icons/all-files/hi/HiKey";
import React from "react";
import { HiBriefcase, HiDocumentSearch, HiOutlineTemplate, HiOutlineViewGridAdd } from "react-icons/hi";
import {
    HiArrowsPointingIn,
    HiChartBar,
    HiChatBubbleLeftEllipsis,
    HiDocumentCheck,
    HiHome,
    HiMiniUserGroup,
    HiPaperClip,
    HiUsers
} from "react-icons/hi2";

const navigationIcon = {
    singleMenu: <HiOutlineViewGridAdd />,
    collapseMenu: <HiOutlineTemplate />,

    //
    home: <HiHome />,
    dashboard: <HiChartBar />,
    companies: <HiBriefcase />,
    companyGroups: <HiArrowsPointingIn />,
    documents: <HiDocumentCheck />,
    employees: <HiMiniUserGroup />,
    users: <HiUsers />,
    attachments: <HiPaperClip />,
    roles: <HiKey />,
    communications: <HiChatBubbleLeftEllipsis />,
    supervisions: <HiDocumentSearch />,
    manuals: <HiBookOpen />,
};

export default navigationIcon;
